import React from 'react'
import CustomerRatings from './components/CustomerRatings/CustomerRatings'

export default function Ratings() {
  return (
    <>
     <CustomerRatings/>
    
    </>
  )
}
