import React from 'react';
import './Logo.css';

const Logo = () => {
  return (
    <>
    <div className="logo">
   <img className='logo-img' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTKuv0IgFiqRbmC_J-C8laXrgwXjASurUnRg&s'/>
   </div>
 
  </>
  );
};

export default Logo;