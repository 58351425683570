import React from 'react'
import CampaignChart from './components/CampaignChart/CampaignChart'

export default function Campaign() {
  return (
    <> 
    <CampaignChart/>
    
    </>
  )
}
