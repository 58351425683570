
import React from 'react'

export default function Mobile({ selected_Device, themes_Types }) {
    return (
        <div>
            Mobile

            selected_Device:{selected_Device}
            themes_Types:{themes_Types}
        </div>


    )
}
