import React from 'react'
import './Loading.css';

export default function Loading() {
  return (<>
<div className="loader-gradient"> </div>
 <div className="loader-gradient_row">
 <div className="loader-gradient_card"> </div>
 <div className="loader-gradient_card"> </div>
 <div className="loader-gradient_card"> </div>
</div>
 
    </> )
}
